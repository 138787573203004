import { ApolloClient, ApolloLink, concat, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { onError } from '@apollo/client/link/error'
import { RetryLink } from '@apollo/client/link/retry'
import Cookies from 'universal-cookie'
import { createClient } from 'graphql-ws'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import store from './redux/store'
import { addError } from './redux/errorSlice'
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs'

const cookies = new Cookies()

const server = process.env.REACT_APP_SERVER || window.location.hostname

/**
 *  The retry link defines that if a request fails, the app tries again with a delay
 * @type {RetryLink}
 */
const retryLink = new RetryLink({
  attempts: {
    max: Infinity,
    retryIf: (error, _operation) => {
      if (error?.statusCode > 500) {
        return error
      }
    }
  },
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  }
})

const wsLink = new GraphQLWsLink(
  createClient({
    url: `wss://${server}/v1/graphql`,
    options: {
      reconnect: true
      // lazy: true,
    },
    connectionParams: {
      authToken: cookies.get('ssbi_session')
    }
  })
)

/**
 * The http link connects the app to the main GraphQL API
 * @type {ApolloLink}
 */
const uploadLink = createUploadLink({ uri: `https://${server}/v1/graphql` })

/**
 * The error link shows nicer errors for certain error codes and detects log ins
 * @type {ApolloLink}
 */
const errorLink = onError(({ response, networkError }) => {
  if (networkError) {
    console.error('Network error: ', networkError)
    if (networkError?.statusCode === 500) {
      console.error('Server Error')
      store.dispatch(addError('server_error'))
      return
    } else if (networkError?.statusCode === 502) {
      console.error('No Server Response')
      store.dispatch(addError('no_server_connection'))
      return
    } else if (networkError?.statusCode === 504) {
      console.error('Timeout')
      store.dispatch(addError('timeout'))
      return
    }
  }

  if (response?.errors) {
    for (const error of response.errors) {
      store.dispatch(addError(error.message))
    }
  }
})

/**
 * The split links splits the traffic between the websocket and the http API
 * @type {ApolloLink}
 */
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
    )
  },
  wsLink,
  concat(retryLink, uploadLink)
)

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext({
    headers: {
      Authorization: cookies.get('ssbi_session')
    }
  })

  return forward(operation)
})

/**
 * The client initialized apollo with the links and auth middleware
 * @type {ApolloClient<NormalizedCacheObject>}
 */
export const client = new ApolloClient({
  link: ApolloLink.from([authLink, errorLink, splitLink]),
  cache: new InMemoryCache()
})
